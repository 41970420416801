//jQuery.noConflict();

jQuery(document).ready(function ($) {

    $('.slide-principal').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        dots: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });



    if (window.location.hash && $(window.location.hash).length) {
    $(window.location.hash).modal('show');
    }
    

    $(".owl-prev").html('<span class="material-icons">arrow_back_ios</span>');
    $(".owl-next").html('<span class="material-icons">arrow_forward_ios</span>');




        // Empresas
    $('.colapsar').click(function() {
        $('.colapsar').removeClass('current');
        $(this).addClass('current');
        $('.box').removeClass('current');
        var $target = $(this).attr('href');
        $($target).addClass('current');
        return false;
    });


    $('.sectores .box:first').addClass('current');
    $('.sectores .colapsar:first').addClass('current');


    // Contact
    $('.wpcf7-form .wpcf7-text').addClass('form-control');
    $('.wpcf7-textarea').addClass('form-control');
    $('.wpcf7-date').addClass('form-control');
    $('.wpcf7-submit').addClass('btn btn-primary');
    //$('.input-xlarge').addClass('form-control');


});
